import { ref, watch, computed } from "@vue/composition-api";
import axios from "axios";
import moment from "moment";
export default function useComplaints() {
  /* REASONS */
  const reasonsFilterText = ref("");
  const reasonsTable = ref(null);
  const reasonTableColumns = [{ key: "reason" }, { key: "actions" }];
  const isLoading = ref(false);

  const loadReasons = async () => {
    try {
      isLoading.value = true;
      let reasons = [];
      const { data } = await axios.get("complaints/reasonsList");
      if (data.data.length == 0) {
        return [];
      }

      reasons = data.data.filter((x) => {
        return (
          reasonsFilterText.value == "" ||
          x.reason.toLowerCase().includes(reasonsFilterText.value.toLowerCase())
        );
      });
      return reasons;
    } catch (e) {
      console.log(e.message);
    } finally {
      isLoading.value = false;
    }
  };

  watch([reasonsFilterText], () => {
    reasonsTable.value.refresh();
  });

  /* COMPLAINTS */
  const complaintsTable = ref(null);
  const filterStartDate = ref(moment().startOf("month").format("YYYY-MM-DD"));
  const filterEndDate = ref(moment().format("YYYY-MM-DD"));
  const filterType = ref(null);
  const filterStatus = ref({
    label: "Pending",
    value: "pending",
  });
  const filterReason = ref(null);
  const filterLodgedBy = ref(null);

  const complaintTableColumns = [
    { key: "date", label: "Date" },
    {
      key: "type",
      label: "Type",
    },
    {
      key: "lodgedBy",
      label: "Lodged By",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "responded",
      label: "Responded By",
    },
    {
      key: "actions",
      label: "Actions",
    },
  ];

  const filterTypeOptions = [
    {
      label: "User",
      value: "user",
    },
    {
      label: "Profile",
      value: "profile",
    },
    {
      label: "Comment",
      value: "comment",
    },
  ];

  const filterStatusOptions = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];
  const filterReasonOptions = ref([]);

  const getAllReasons = async () => {
    try {
      isLoading.value = true;
      const { data } = await axios.get("complaints/reasonsList");
      if (data.data.length == 0) {
        return [];
      }
      filterReasonOptions.value = data.data.map((x) => {
        return {
          label: x.reason,
          value: x._id,
        };
      });
    } catch (e) {
      console.log(e.message);
    } finally {
      isLoading.value = false;
    }
  };
  getAllReasons();

  const getComplaints = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("dateRange", `["${filterStartDate.value}", "${filterEndDate.value}"]`);
      formData.append("type", filterType.value ? filterType.value.value : "");
      formData.append("status", filterStatus.value ? filterStatus.value.value : "");
      const { data } = await axios.post("complaints/searchComplaints", formData);
      if (data.data.length == 0) {
        return [];
      }

      const complaints = data.data.map((row) => {
        return {
          _id: row._id,
          date: moment(row.createdAt).format("YYYY-MM-DD"),
          type: row.type,
          lodgedBy: row.launchedBy,
          status: row.status,
          responded: row.respondedBy,
          respondedDate: row.respondDateTime
            ? moment(row.respondDateTime).format("YYYY-MM-DD")
            : "",
          reason: row.reasonId?.reason,
        };
      });
      return complaints;
    } catch (e) {
      console.log(e.message);
    } finally {
      isLoading.value = false;
    }
  };

  watch(
    [filterStartDate, filterEndDate, filterType, filterStatus, filterReason, filterLodgedBy],
    () => {
      complaintsTable.value.refresh();
    }
  );

  return {
    /* reasons */
    reasonsTable,
    reasonTableColumns,
    reasonsFilterText,
    loadReasons,

    /* Complaints */
    complaintsTable,
    filterStartDate,
    filterEndDate,
    filterStatus,
    filterType,
    filterReason,
    filterLodgedBy,

    filterTypeOptions,
    filterStatusOptions,
    filterReasonOptions,
    complaintTableColumns,
    getComplaints,
    isLoading,
  };
}
