<template>
  <div>
    <h3>Complaints</h3>
    <b-row>
      <b-col md="2">
        <b-card header="Filter Complaints">
          <b-row>
            <b-col md="12">
              <b-form-group label="From Date">
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  locale="en"
                  v-model="filterStartDate"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="To Date">
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  locale="en"
                  v-model="filterEndDate"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Lodged By">
                <v-select v-model="filterLodgedBy" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Type">
                <v-select v-model="filterType" :options="filterTypeOptions" clearable />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Status">
                <v-select v-model="filterStatus" :options="filterStatusOptions" clearable />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group label="Reason">
                <v-select v-model="filterReason" :options="filterReasonOptions" clearable />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="10">
        <b-card header="Complaints" no-body>
          <b-table
            ref="complaintsTable"
            :items="getComplaints"
            :fields="complaintTableColumns"
            responsive
            show-empty
            :empty-text="isLoading ? 'Loading...' : 'No matching records found'"
          >
            <template #cell(lodgedBy)="data">
              <b-link target="_blank" :href="`/profile/${data.item.lodgedBy.username}`">{{
                data.item.lodgedBy.username
              }}</b-link>
              <span class="text-muted"> - {{ data.item.lodgedBy.userType }}</span
              ><br /><span class="text-small">{{ data.item.reason }}</span>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.item.status == 'pending'
                    ? 'warning'
                    : data.item.status == 'accepted'
                    ? 'success'
                    : 'danger'
                "
                >{{ data.item.status }}</b-badge
              >
            </template>
            <template #cell(responded)="data">
              <span v-if="!data.item.responded" class="text-muted">Not responded yet</span>
              <b-link
                target="_blank"
                :href="`/profile/${data.item.responded.username}`"
                v-if="data.item.responded"
                >{{ data.item.responded.username }}</b-link
              ><span v-if="data.item.responded" class="tex-muted">
                on {{ data.item.respondedDate }}</span
              >
            </template>
            <template #cell(actions)="data">
              <b-button :href="`/complaints/details/${data.item._id}`" size="sm"
                >View Details</b-button
              >
            </template>
          </b-table>
        </b-card>
        <div class="text-muted">
          <i class="fa fa-info-circle"></i> Click on a Type to view associated profile/user/comment.
          <br />
          <i class="fa fa-info-circle"></i> Hover the lodgedby user to view the reason.
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BFormInput,
  BFormGroup,
  BButton,
  BFormDatepicker,
  BLink,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useComplaints from "./useComplaints";
import axios from "axios";
import { ref } from "@vue/composition-api";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BFormGroup,
    BButton,
    BCardBody,
    BFormDatepicker,
    vSelect,
    BLink,
    BBadge,
  },
  data() {
    return {
      reasons: [],
    };
  },
  created: async () => {},
  setup() {
    const {
      filterStartDate,
      filterEndDate,
      filterStatus,
      filterType,
      filterLodgedBy,
      filterReason,

      filterStatusOptions,
      filterTypeOptions,
      filterReasonOptions,

      complaintTableColumns,
      complaintsTable,
      getComplaints,
      isLoading,
    } = useComplaints();

    return {
      filterStartDate,
      filterEndDate,
      filterStatus,
      filterType,
      filterLodgedBy,
      filterReason,

      filterStatusOptions,
      filterTypeOptions,
      filterReasonOptions,
      complaintsTable,
      complaintTableColumns,
      getComplaints,
      isLoading,
    };
  },
};
</script>
